import { Datagrid, ListProps, NumberInput, TextField } from "react-admin";
import { CommonList, NumField, ReferenceEquipmentCardField } from "../Common";

const userPartyEquipmentCardFilters = [
  <NumberInput key="partyNumber" source="PartyNumber" label="パーティーNO" />,
  <NumberInput key="characterCardSlotNumber" source="CharacterCardSlotNumber" label="MCカードスロットNO" />,
  <NumberInput key="equipmentCardSlotNumber" source="EquipmentCardSlotNumber" label="メモリーカードスロットNO" />,
  <NumberInput key="equipmentCardId" source="EquipmentCardId" label="EquipmentCardId" />,
  <NumberInput key="userEquipmentCardNumber" source="UserEquipmentCardNumber" label="ユーザーMCカードNO" />,
];

export const UserPartyEquipmentCardList = (props: ListProps) => (
  <CommonList {...props} addFilters={userPartyEquipmentCardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="PartyNumber" label="パーティーNO" />
      <NumField source="CharacterCardSlotNumber" label="MCカードスロットNO" />
      <NumField source="EquipmentCardSlotNumber" label="メモリーカードスロットNO" />
      <NumField source="EquipmentCardId" label="EquipmentCardId" />
      <NumField source="UserEquipmentCardNumber" label="ユーザーMCカードNO" />
      <ReferenceEquipmentCardField />
    </Datagrid>
  </CommonList>
);
