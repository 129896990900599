import { useState, useEffect } from "react";
import { DateTimeInput, DateTimeInputProps, TextInput, useRecordContext, SelectInput, NumberInput, required, useDataProvider, InputProps } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { ContentTypes, ContentType } from "common/ContentType";

export const TextWithCharCountInput = ({ source, maxLength, ...props }: any) => {
  const record = useRecordContext();
  const [charCount, setCharCount] = useState(record ? record[source]?.length : 0);

  const handleTextInputChange = (event: any) => {
    setCharCount(event.target.value.length);
  };

  return (
    <div>
      <TextInput source={source} onChange={handleTextInputChange} defaultValue={record ? record[source] : ""} {...(maxLength && { maxLength })} {...props} />
      <div>
        <div>{maxLength ? `${charCount} / ${maxLength}` : charCount}</div>
      </div>
    </div>
  );
};

export const DateTimeWithSecInput = (props: any) => <DateTimeInput {...props} inputProps={{ step: 1 }} format={formatDateTime} />;

type ButtonProps = {
  calcSetDateTimeSec: () => Date;
  buttonText: string;
};

type DateTimeSecWithButtonInputProps = DateTimeInputProps & ButtonProps;

export const DateTimeSecWithButtonInput = ({ source, validate, buttonText, calcSetDateTimeSec, ...rest }: DateTimeSecWithButtonInputProps) => {
  const { setValue } = useFormContext();

  const onClick = () => {
    const value = calcSetDateTimeSec();
    setValue(source, value);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap="10px">
      <DateTimeWithSecInput source={source} validate={validate} rest={rest} />
      <Button variant="outlined" onClick={onClick}>
        {buttonText}
      </Button>
    </Box>
  );
};

const leftPad =
  (nb = 2) =>
  (value: any) =>
    ("0".repeat(nb) + value).slice(-nb);
const leftPad4 = leftPad(4);
const leftPad2 = leftPad(2);

const convertDateToString = (value: Date) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return "";
  const yyyy = leftPad4(value.getFullYear());
  const MM = leftPad2(value.getMonth() + 1);
  const dd = leftPad2(value.getDate());
  const hh = leftPad2(value.getHours());
  const mm = leftPad2(value.getMinutes());
  const ss = leftPad2(value.getSeconds());
  return `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}`;
};

const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

const formatDateTime = (value: string | Date) => {
  if (value == null || value === "") {
    return "";
  }

  if (value instanceof Date) {
    return convertDateToString(value);
  }

  if (dateTimeRegex.test(value)) {
    return value;
  }

  return convertDateToString(new Date(value));
};

const fetchContentList = async (contentType: any, dataProvider: any) => {
  let resource = "";
  switch (contentType) {
    case ContentType.CHARACTER_CARD:
      resource = "CharacterCard";
      break;
    case ContentType.EQUIPMENT_CARD:
      resource = "EquipmentCard";
      break;
    case ContentType.ITEM:
      resource = "Item";
      break;
    case ContentType.QUEST_STORY_EPISODE:
      resource = "QuestStoryEpisode";
      break;
    case ContentType.HONOR:
      resource = "Honor";
      break;
    default:
      return [];
  }
  const { data } = await dataProvider.getList(resource, { pagination: { page: 1, perPage: 9999999 }, sort: { field: "id", order: "ASC" }, filter: {} });
  return data;
};

export const ContentTypeWithUnifiedContentInput = () => {
  const [contentType, setContentType] = useState(0);
  const [contentList, setContentList] = useState<{ id: string; name: string }[]>([]);
  const dataProvider = useDataProvider();
  const { setValue } = useFormContext();
  const contentId = useWatch({ name: "ContentSelect" });
  const record = useRecordContext();

  useEffect(() => {
    if (record && record.ContentType) {
      setContentType(record.ContentType);
    }
  }, [record]);

  useEffect(() => {
    if (contentType) {
      fetchContentList(contentType, dataProvider).then(setContentList);
    } else {
      setContentList([]);
    }
  }, [contentType, setValue]);

  useEffect(() => {
    setValue("ContentId", contentId);
  }, [contentId, setValue]);

  const choices = contentList.map((item: any) => {
    switch (contentType) {
      case ContentType.CHARACTER_CARD:
        return { id: item.id, name: `${item.id}: ${item.card_name_ruby} / ${item.card_name}` };
      case ContentType.EQUIPMENT_CARD:
        return { id: item.id, name: `${item.id}: ${item.name} / ${item.card_name}` };
      case ContentType.ITEM:
        return { id: item.id, name: `${item.id}: ${item.name}` };
      case ContentType.QUEST_STORY_EPISODE:
        return { id: item.id, name: `${item.id}: ${item.title} / ${item.flavor}` };
      case ContentType.HONOR:
        return { id: item.id, name: `${item.id}: ${item.name}` };
      default:
        return { id: item.id, name: item.name };
    }
  });

  return (
    <>
      <SelectInput source="ContentType" label="コンテンツタイプ" choices={ContentTypes} validate={required()} onChange={(e) => setContentType(e.target.value)} />
      {contentType !== 0 && contentList.length > 0 && <SelectInput source="ContentSelect" label="コンテンツ選択" choices={choices} />}
      <NumInput source="ContentId" label="コンテンツID" validate={(value: any) => (value === null ? "Required" : undefined)} defaultValue={0} />
    </>
  );
};

export const NumInput = (props: InputProps) => {
  const customValidate = (value: any) => (value !== null && value < 0 ? "0以上の値が必要です" : undefined);
  const combinedValidate = props.validate ? (Array.isArray(props.validate) ? [...props.validate, customValidate] : [props.validate, customValidate]) : customValidate;

  return <NumberInput {...props} validate={combinedValidate} />;
};
