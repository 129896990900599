export type FileInputResult = {
  rawFile: File;
  src: string;
  title: string;
};

export const readFileAsText = (file: File): Promise<string> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        resolve(e.target.result.toString());
      }
      reject(new DOMException("Problem read input file."));
    };
    fileReader.readAsText(file);
  });
};

export const readAsBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const result = e.target?.result;
      if (typeof result !== "string") throw TypeError("Reader did not return string.");
      resolve(result.split("base64,")[1]);
    };

    reader.onerror = (e: ProgressEvent<FileReader>) => {
      reject(e.target?.error);
    };

    reader.readAsDataURL(file);
  });

export const base64ToImageFile = (base64String: string, fileName: string): File | null => {
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) {
    console.warn("Invalid file name format.");
    return null;
  }

  let mimeType = fileName.substring(lastDotIndex + 1);
  if (mimeType === "jpg") {
    mimeType = "jpeg";
  }

  if (!["jpeg", "png"].includes(mimeType)) {
    console.warn("Invalid file type.");
    return null;
  }

  const bin = window.atob(base64String.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }

  return new File([buffer.buffer], fileName, { type: `image/${mimeType}` });
};
