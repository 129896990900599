import React, { createContext, useContext, useEffect, useState } from "react";
import { LocalStorageUtil } from "web_storage/local_storage";

export const ApiEndpointContext = createContext({
  apiEndpoint: 0,
  setApiEndpoint: (endpoint: number) => {},
});

export const useApiEndpoint = () => useContext(ApiEndpointContext);

export const ApiEndpointProvider = ({ children }: any) => {
  const [apiEndpoint, setApiEndpoint] = useState(LocalStorageUtil.getLocalItem("apiEndpoint", 0));

  return <ApiEndpointContext.Provider value={{ apiEndpoint, setApiEndpoint }}>{children}</ApiEndpointContext.Provider>;
};
