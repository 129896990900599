interface LocalStorageData {
  apiEndpoint: number;
}

class LocalStorageUtilClass {
  public setLocalItem<P extends keyof LocalStorageData>(key: P, item: LocalStorageData[P]) {
    try {
      const value = typeof item === "string" ? item : JSON.stringify(item);
      localStorage.setItem(key, value);
    } catch (error) {
      console.error("failed localStorage.setItem. error:", error);
    }
  }

  public getLocalItem<P extends keyof LocalStorageData>(key: P, defaultValue: LocalStorageData[P]) {
    const value = localStorage.getItem(key);
    if (value === null) {
      return defaultValue;
    }
    return (typeof defaultValue === "string" ? value : JSON.parse(value)) as LocalStorageData[P];
  }
}

export const LocalStorageUtil = new LocalStorageUtilClass();
