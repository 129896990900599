import React, { useState, useEffect, useLayoutEffect, useCallback, useRef, ChangeEvent, MouseEvent } from "react";
import {
  Toolbar,
  SaveButton,
  Create,
  Edit,
  EditButton,
  Datagrid,
  SimpleForm,
  TextField,
  TextInput,
  DeleteWithConfirmButton,
  useDataProvider,
  useNotify,
  ImageInput,
  ImageField,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  useRedirect,
  minLength,
  maxLength,
  Validator,
  required,
  ListProps,
  EditProps,
  Button,
  FunctionField,
  useRecordContext,
  AutocompleteInput,
} from "react-admin";
import { EditTitle, PathField } from "../Common";
import { useMutation } from "react-query";
import { useFormContext } from "react-hook-form";
import { HTMLPreviewRawText, AnnouncementBuilderComponent } from "./htmlComponent";
import { AnnouncementTemplateLoader } from "./templateLoader";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField, NumField } from "../Common";
import { DateTimeSecWithButtonInput } from "components/Common/Input";
import { ImageSize } from "./imageSize";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { DataProviderWithCustomMethods } from "providers/dataProvider";
import { Box, IconButton, Button as MuiButton, Stack } from "@mui/material";
import { FileDownloadOutlined, Publish } from "@mui/icons-material";
import { FormatSimpleDateToJST } from "utils/date";
import {
  Announcement,
  AnnouncementCreateFormInput,
  AnnouncementCreateRequest,
  AnnouncementExportDataResponse,
  AnnouncementUpdateFormInput,
  AnnouncementUpdateRequest,
} from "./interface";
import { base64ToImageFile, FileInputResult, readAsBase64 } from "utils/file";

const filters = [
  <TextInput key="id" source="Id" label="Id" />,
  <TextInput key="title" source="Title_like" label="Title Like" />,
  <TextInput key="body" source="Body_like" label="Body Like" />,
  <TextInput key="path" source="Path_like" label="Path Like" />,
  <DateTimeWithSecInput key="openAtFrom" source="OpenAt_from" label="OpenAt From" />,
  <DateTimeWithSecInput key="openAtTo" source="OpenAt_to" label="OpenAt To" />,
  <DateTimeWithSecInput key="closeFrom" source="Close_from" label="CloseAt From" />,
  <DateTimeWithSecInput key="closeTo" source="Close_to" label="CloseAt To" />,
  <TextInput key="category" source="Category_like" label="Category Like" />,
  <TextInput key="tag" source="Tag_like" label="Tag Like" />,
  <TextInput key="bannerUrl" source="BannerUrl_like" label="BannerUrl Like" />,
  <TextInput key="thumbnailUrl" source="ThumbnailUrl_like" label="ThumbnailUrl Like" />,
  <NumberInput key="priority" source="Priority" label="Priority" />,
];

const formValidate: { [field: string]: Validator[] } = {
  title: [required("必須入力項目です")],
  body: [required("必須入力項目です")],
  thumbnail: [required("必須入力項目です")],
  openAt: [required("必須入力項目です")],
  closeAt: [required("必須入力項目です")],
  tag: [required("必須入力項目です"), minLength(1), maxLength(8, "8文字以内で入力してください")],
  category: [required("必須入力項目です")],
  priority: [required("必須入力項目です")],
};

export const announcementTag = {
  info: "お知らせ",
  importance: "重要",
  update: "アップデート情報",
  event: "イベント",
  story: "ストーリー追加",
  gacha: "ガチャ",
  shop: "ショップ",
  bug: "不具合情報",
  campaign: "キャンペーン",
  maintenance: "メンテナンス",
} as const;

const announcementTagChoices: { id: string; name: string }[] = Object.values(announcementTag).map((value) => ({ id: value, name: value }));

export const AnnouncementList = (props: ListProps<Announcement>) => {
  const [showBody, setShowBody] = useState(false);
  const [showImages, setShowImages] = useState(false);

  const toggleShowBody = () => {
    setShowBody(!showBody);
  };

  const toggleShowImages = () => {
    setShowImages(!showImages);
  };

  return (
    <CommonList {...props} filters={filters} sort={{ field: "Priority", order: "DESC" }} showWildcardHelp={true}>
      <Stack direction="row" spacing={1}>
        <Button onClick={toggleShowBody} startIcon={showBody ? <VisibilityOffIcon /> : <VisibilityIcon />} label={showBody ? "本文非表示" : "本文表示"} />
        <Button onClick={toggleShowImages} startIcon={showImages ? <VisibilityOffIcon /> : <VisibilityIcon />} label={showBody ? "画像非表示" : "画像表示"} />
      </Stack>
      <Datagrid bulkActionButtons={false}>
        <TextField source="Id" label="Id" />
        <TextField source="Title" label="Title" />
        {showBody && <TextField source="Body" label="Body" />}
        <PathField source="Path" label="Path" />
        <YMDHMSDateField source="OpenAt" label="OpenAt" />
        <YMDHMSDateField source="CloseAt" label="CloseAt" />
        <TextField source="Category" label="Category" />
        <TextField source="Tag" label="Tag" />
        {showImages && <ImageField source="BannerUrl" label="Banner" />}
        {showImages && <FunctionField render={(r: Announcement) => (r.BannerUrl ? <ImageSize source="BannerUrl" label="BannerSize" /> : null)} />}
        <PathField source="ThumbnailUrl" label="ThumbnailURL" />
        {showImages && <ImageField source="ThumbnailUrl" label="Thumbnail" />}
        {showImages && <ImageSize source="ThumbnailUrl" label="ThumbnailSize" />}
        <NumField source="Priority" label="Priority" />
        <EditButton />
        <FunctionField render={(r: Announcement) => <ExportButton announcementId={r.Id} />} />
      </Datagrid>
    </CommonList>
  );
};

const HTMLCreateToolbar = () => {
  const dataProvider = useDataProvider<DataProviderWithCustomMethods>();
  const notify = useNotify();
  const { getValues, formState } = useFormContext<AnnouncementCreateFormInput>();
  const redirect = useRedirect();

  const inputBanner = getValues("Banner");
  const inputThumbnail = getValues("Thumbnail");
  const inputImages = getValues("Images");

  const { mutate, isLoading } = useMutation(
    ["create"],
    async () => {
      const e = document.getElementById("preview-html") as HTMLIFrameElement;
      const docType = "<!DOCTYPE html>";
      const html = e.contentDocument!.documentElement.outerHTML;

      const banner = inputBanner?.rawFile
        ? {
            Data: await readAsBase64(inputBanner.rawFile),
            FileName: inputBanner.rawFile.name,
          }
        : undefined;

      const thumbnail = inputThumbnail?.rawFile
        ? {
            Data: await readAsBase64(inputThumbnail.rawFile),
            FileName: inputThumbnail.rawFile.name,
          }
        : undefined;

      const images = await Promise.all(
        inputImages?.map(async (img) => ({
          Data: await readAsBase64(img.rawFile),
          FileName: img.rawFile.name,
        })) ?? []
      );

      return dataProvider.create<AnnouncementCreateRequest>("Announcement", {
        data: {
          Id: getValues("Id"),
          Title: getValues("Title"),
          Body: getValues("Body"),
          Html: docType + html,
          OpenAt: getValues("OpenAt"),
          CloseAt: getValues("CloseAt"),
          Category: getValues("Category"),
          Tag: getValues("Tag"),
          Priority: getValues("Priority"),
          Images: images,
          Banner: banner,
          Thumbnail: thumbnail,
        },
      });
    },
    {
      onSuccess: () => {
        notify("success", { type: "success" });
        redirect("/Announcement");
      },
      onError: (error: unknown) => {
        notify(error instanceof Error ? error.message : "Failed to create announcement.", { type: "warning" });
      },
    }
  );

  const onSubmit = useCallback(async () => {
    if (!formState.isValid) {
      return;
    }
    await mutate();
  }, [formState.isValid, notify, mutate]);

  return (
    <Toolbar>
      <SaveButton label="作成" onClick={onSubmit} disabled={isLoading} />
    </Toolbar>
  );
};

const announcementTemplateBucketPath = process.env.REACT_APP_ANNOUNCEMENT_TEMPLATE_BUCKET_URL;

export const AnnouncementCreate = () => {
  const [html, setHTML] = useState("");

  useLayoutEffect(() => {
    (async () => {
      const h = await AnnouncementTemplateLoader.load(`${announcementTemplateBucketPath!}/index.html`);
      const replaced = AnnouncementTemplateLoader.replaceURL(h, announcementTemplateBucketPath!);
      setHTML(replaced);
    })();
  }, []);

  return (
    <Create>
      <SimpleForm toolbar={<HTMLCreateToolbar />}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
          <ImportButton />
        </Box>
        <AnnouncementBuilderComponent />
        <TextInput source="Id" helperText="指定しない場合はランダムな値が設定" />
        <TextInput source="Title" isRequired validate={formValidate.title} />
        <TextInput source="Body" isRequired multiline fullWidth validate={formValidate.body} />
        <ImageInput source="Thumbnail" accept="image/*" isRequired validate={formValidate.thumbnail}>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="Banner" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="Images" label="画像">
          <SimpleFormIterator inline>
            <ImageInput source="" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <AutocompleteInput source="Category" isRequired validate={formValidate.category} choices={announcementTagChoices} />
        <AutocompleteInput source="Tag" isRequired validate={formValidate.tag} choices={announcementTagChoices} />
        <DateTimeWithSecInput source="OpenAt" isRequired validate={formValidate.openAt} />
        <DateTimeSecWithButtonInput source="CloseAt" isRequired validate={formValidate.closeAt} buttonText="常時表示" calcSetDateTimeSec={() => new Date("2999-12-31T00:00:00")} />
        <NumberInput source="Priority" isRequired validate={formValidate.priority} />
        <HTMLPreviewRawText html={html} />
      </SimpleForm>
    </Create>
  );
};

interface UpdateToolebarProps {
  setHTML: React.Dispatch<React.SetStateAction<string>>;
}
const AnnouncementUpdateToolbar = (props: UpdateToolebarProps) => {
  const record = useRecordContext<Announcement>();
  const dataProvider = useDataProvider<DataProviderWithCustomMethods>();
  const notify = useNotify();
  const redirect = useRedirect();
  const { getValues, formState } = useFormContext<AnnouncementUpdateFormInput>();

  const inputBanner = getValues("Banner");
  const inputThumbnail = getValues("Thumbnail");
  const inputImages = getValues("Images");

  useEffect(() => {
    AnnouncementTemplateLoader.load(record.Path).then((h) => props.setHTML(h));
  }, []);

  const { mutate, isLoading } = useMutation(
    ["update"],
    async () => {
      const e = document.getElementById("preview-html") as HTMLIFrameElement;
      const docType = "<!DOCTYPE html>";
      const html = e.contentDocument!.documentElement.outerHTML;

      const banner = inputBanner?.rawFile
        ? {
            Data: await readAsBase64(inputBanner.rawFile),
            FileName: inputBanner.rawFile.name,
          }
        : undefined;

      const thumbnail = inputThumbnail?.rawFile
        ? {
            Data: await readAsBase64(inputThumbnail.rawFile),
            FileName: inputThumbnail.rawFile.name,
          }
        : undefined;

      const images = await Promise.all(
        inputImages?.map(async (img) => ({
          Data: await readAsBase64(img.rawFile),
          FileName: img.rawFile.name,
        })) ?? []
      );

      return dataProvider.update<AnnouncementUpdateRequest>("Announcement", {
        id: record.id,
        previousData: {
          title: record.Title,
          body: record.Body,
          openAt: record.OpenAt,
          closeAt: record.CloseAt,
          category: record.Category,
          tag: record.Tag,
          priority: record.Priority,
        },
        data: {
          title: getValues("Title"),
          html: docType + html,
          body: getValues("Body"),
          openAt: getValues("OpenAt"),
          closeAt: getValues("CloseAt"),
          category: getValues("Category"),
          tag: getValues("Tag"),
          banner,
          thumbnail,
          images,
          priority: getValues("Priority"),
          isResetReadFlag: getValues("IsResetUserRead"),
        },
      });
    },
    {
      onSuccess: () => {
        notify("success", { type: "success" });
        redirect("/Announcement");
      },
      onError: (error: unknown) => {
        notify(error instanceof Error ? error.message : "Failed to update announcement.", { type: "warning" });
      },
    }
  );

  const onSubmit = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      if (!formState.isValid) {
        notify("Invalit form data", { type: "warning" });
        e.preventDefault();
        return;
      }
      await mutate();
    },
    [formState.isValid, notify, mutate]
  );

  return (
    <Toolbar>
      <SaveButton label="更新" onClick={onSubmit} disabled={isLoading} />
      <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" translateOptions={{ name: record.id }} />
    </Toolbar>
  );
};

export const AnnouncementEdit = (props: EditProps<Announcement>) => {
  const [html, setHTML] = useState("");

  return (
    <Edit {...props} title={<EditTitle name="Announcement" />}>
      <SimpleForm toolbar={<AnnouncementUpdateToolbar setHTML={setHTML} />}>
        <AnnouncementBuilderComponent />
        <TextInput source="Title" isRequired validate={formValidate.title} />
        <TextInput source="Body" multiline fullWidth isRequired validate={formValidate.body} />
        <ImageInput source="Thumbnail" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="Banner" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <ArrayInput source="Images" label="画像">
          <SimpleFormIterator inline>
            <ImageInput source="" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <DateTimeWithSecInput source="OpenAt" isRequired validate={formValidate.openAt} />
        <DateTimeWithSecInput source="CloseAt" isRequired validate={formValidate.closeAt} />
        <AutocompleteInput source="Category" isRequired validate={formValidate.category} choices={announcementTagChoices} />
        <AutocompleteInput source="Tag" isRequired validate={formValidate.tag} choices={announcementTagChoices} />
        <NumberInput source="Priority" isRequired validate={formValidate.priority} />
        <BooleanInput source="IsResetUserRead" defaultValue={false} />
        <HTMLPreviewRawText html={html} />
      </SimpleForm>
    </Edit>
  );
};

const ExportButton = (props: { announcementId: string }) => {
  const dataProvider = useDataProvider<DataProviderWithCustomMethods>();
  const notify = useNotify();

  const downloadExportData = useCallback(async () => {
    try {
      const res = await dataProvider.getResourceAction<AnnouncementExportDataResponse>("Announcement", "ExportData", { id: props.announcementId });
      if (!res || !res.data) {
        throw new Error("Export data is empty.");
      }

      const jsonString = JSON.stringify(res.data, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `AnnouncementExport_${res.data.Title}_${FormatSimpleDateToJST(new Date(res.data.OpenAt))}-${FormatSimpleDateToJST(new Date(res.data.CloseAt))}_${FormatSimpleDateToJST()}.json`;
      document.body.appendChild(link);
      link.style.display = "none";
      link.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (e: unknown) {
      notify(e instanceof Error ? e.message : "Failed to export data.", { type: "warning" });
    }
  }, [notify]);

  return (
    <IconButton onClick={downloadExportData} size="small" color="primary">
      <FileDownloadOutlined />
    </IconButton>
  );
};

const ImportButton = () => {
  const { setValue } = useFormContext<AnnouncementCreateFormInput>();
  const notify = useNotify();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const setFormValues = useCallback(
    (data: AnnouncementExportDataResponse) => {
      setValue("Title", data.Title);
      setValue("Category", data.Category);
      setValue("Tag", data.Tag);
      setValue("Body", data.Body);

      if (process.env.REACT_APP_ENV !== "prod") {
        setValue("OpenAt", data.OpenAt);
        setValue("CloseAt", data.CloseAt);
      }

      const imageDecodedErrors: Error[] = [];

      if (data.Banner && data.Banner.FileName) {
        const bannerImage = base64ToImageFile(data.Banner.Data, data.Banner.FileName);
        if (bannerImage) {
          const previewUrl = URL.createObjectURL(bannerImage);
          setValue("Banner", { title: data.Banner.FileName, src: previewUrl, rawFile: bannerImage });
        } else {
          imageDecodedErrors.push(new Error(`Failed to import banner image. src: ${data.Banner.FileName}`));
        }
      }

      if (data.Thumbnail.FileName) {
        const thumbnailImage = base64ToImageFile(data.Thumbnail.Data, data.Thumbnail.FileName);
        if (thumbnailImage) {
          const previewUrl = URL.createObjectURL(thumbnailImage);
          setValue("Thumbnail", { title: data.Thumbnail.FileName, src: previewUrl, rawFile: thumbnailImage });
        } else {
          imageDecodedErrors.push(new Error(`Failed to import thumbnail image. src: ${data.Thumbnail.FileName}`));
        }
      }

      if (data.Images.length > 0) {
        const imageFiles = data.Images.map((img) => {
          const file = base64ToImageFile(img.Data, img.FileName);
          if (!file) {
            imageDecodedErrors.push(new Error(`Failed to import image. src: ${img.FileName}`));
            return null;
          }
          const previewUrl = URL.createObjectURL(file);
          return { title: img.FileName, src: previewUrl, rawFile: file } as FileInputResult;
        });
        if (!imageFiles.includes(null)) {
          setValue("Images", imageFiles as FileInputResult[]);
        }
      }

      if (imageDecodedErrors.length > 0) {
        console.warn(imageDecodedErrors);
        throw new Error("Failed to import image files.");
      }
    },
    [setValue]
  );

  const handleFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      try {
        const jsonString = await file.text();
        const data = JSON.parse(jsonString) as AnnouncementExportDataResponse;
        setFormValues(data);
        notify("Imported data successfully.", { type: "info" });
      } catch (e: unknown) {
        notify(e instanceof Error ? e.message : "Failed to read file.", { type: "warning" });
      }
    },
    [notify, setFormValues]
  );

  return (
    <>
      <MuiButton startIcon={<Publish />} onClick={handleButtonClick}>
        Import
      </MuiButton>
      <input type="file" accept=".json" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
    </>
  );
};
