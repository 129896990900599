import { Datagrid, ListProps, NumberInput, TextField } from "react-admin";
import { CommonList, NumField, ReferenceCharacterCardField } from "../Common";

const userPartyCharacterCardFilters = [
  <NumberInput key="partyNumber" source="PartyNumber" label="パーティーNO" />,
  <NumberInput key="characterCardSlotNumber" source="CharacterCardSlotNumber" label="スロットNO" />,
  <NumberInput key="characterCardId" source="CharacterCardId" label="CharacterCardId" />,
];

export const UserPartyCharacterCardList = (props: ListProps) => (
  <CommonList {...props} addFilters={userPartyCharacterCardFilters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="UserId" label="ユーザーID" />
      <NumField source="PartyNumber" label="パーティーNO" />
      <NumField source="CharacterCardSlotNumber" label="スロットNO" />
      <NumField source="CharacterCardId" label="CharacterCardId" />
      <ReferenceCharacterCardField />
    </Datagrid>
  </CommonList>
);
