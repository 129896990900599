import React, { useState, useCallback, useEffect } from "react";
import { useMutation } from "react-query";
import { Title, useDataProvider } from "react-admin";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Chip,
  Divider,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
  Fab, // Scroll-to-top button
  Zoom, // Scroll-to-top transition
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // Accordion icon
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"; // Expand all icon
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess"; // Collapse all icon
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"; // Scroll-to-top icon

// --- Helper Function for Skill Type ---
const getSkillTypeLabel = (type: number): string => {
  switch (type) {
    case 1:
      return "Active";
    case 2:
      return "Passive";
    case 3:
      return "Special";
    default:
      return "Unknown";
  }
};

const getSkillTypeColor = (type: number): "primary" | "secondary" | "success" | "default" => {
  switch (type) {
    case 1:
      return "primary";
    case 2:
      return "success";
    case 3:
      return "secondary";
    default:
      return "default";
  }
};

// --- Character Member Card Component ---
interface MemberCardProps {
  member: any;
  characterDetails: Record<number, any>;
  equipmentDetails: Record<number, any>;
  activeSkillDetails: Record<number, any>;
  passiveSkillDetails: Record<number, any>;
  specialSkillDetails: Record<number, any>;
  areAccordionsExpanded: boolean; // Controlled by parent for global toggle
}

const MemberCard: React.FC<MemberCardProps> = ({
  member,
  characterDetails,
  equipmentDetails,
  activeSkillDetails,
  passiveSkillDetails,
  specialSkillDetails,
  areAccordionsExpanded,
}) => {
  // Local state for individual accordion control
  const [isSkillsExpanded, setIsSkillsExpanded] = useState(areAccordionsExpanded);
  const [isEquipmentExpanded, setIsEquipmentExpanded] = useState(areAccordionsExpanded);

  // Sync local state with parent's global state
  useEffect(() => {
    setIsSkillsExpanded(areAccordionsExpanded);
    setIsEquipmentExpanded(areAccordionsExpanded);
  }, [areAccordionsExpanded]);

  // Data extraction and null checks
  const characterCard = member.CalculatedPartyCharacterCard?.CalculatedCharacterCard;
  const characterId = characterCard?.CharacterCardId;
  const characterInfo = characterId ? characterDetails[characterId] : null;
  const equipmentCards = member.CalculatedPartyEquipmentCards || [];
  const skills = characterCard?.CharacterCardSkills || [];
  const slotNumber = member.CalculatedPartyCharacterCard?.CharacterCardSlotNumber;

  if (!characterCard || !characterId) {
    return (
      <Card variant="outlined" sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", bgcolor: "grey.50" }}>
        <CardContent>
          <Typography color="text.secondary" variant="body2">
            Character data missing for slot {slotNumber || "N/A"}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  // Individual accordion toggle handlers
  const handleSkillsAccordionChange = () => setIsSkillsExpanded((prev) => !prev);
  const handleEquipmentAccordionChange = () => setIsEquipmentExpanded((prev) => !prev);

  return (
    <Card variant="outlined" sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {/* Card Header */}
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: "secondary.light" }}>{characterInfo?.card_name?.charAt(0) || "?"}</Avatar>}
        title={`Slot ${slotNumber}: ${characterInfo?.card_name || "Unknown Character"}`}
        subheader={characterInfo?.card_name_ruby || `ID: ${characterId}`}
        titleTypographyProps={{ variant: "h6", fontWeight: "bold", fontSize: "1.1rem" }}
        subheaderTypographyProps={{ variant: "caption" }}
        sx={{ bgcolor: "grey.100", borderBottom: "1px solid", borderColor: "divider" }}
      />
      {/* Card Content */}
      <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column", gap: 2, pt: 1.5 }}>
        {/* Stats Section */}
        <Box>
          <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: "medium" }}>
            Base Stats
          </Typography>
          <TableContainer component={Paper} variant="outlined" elevation={0}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ width: "30%", fontWeight: "medium", border: 0, py: 0.5 }}>HP</TableCell>
                  <TableCell sx={{ border: 0, py: 0.5 }}>{characterCard.HP}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "medium", border: 0, py: 0.5 }}>ATK</TableCell>
                  <TableCell sx={{ border: 0, py: 0.5 }}>{characterCard.ATK}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "medium", border: 0, py: 0.5 }}>DEF</TableCell>
                  <TableCell sx={{ border: 0, py: 0.5 }}>{characterCard.DEF}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* Skills Accordion */}
        <Accordion
          sx={{ "&:before": { display: "none" }, border: 1, borderColor: "divider", margin: "0 !important" }}
          elevation={0}
          expanded={isSkillsExpanded}
          onChange={handleSkillsAccordionChange}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-skills-content-${characterId}-${slotNumber}`}
            id={`panel-skills-header-${characterId}-${slotNumber}`}
            sx={{ bgcolor: "action.hover", minHeight: "40px", ".MuiAccordionSummary-content": { marginY: "8px" } }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
              Skills ({skills.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow sx={{ bgcolor: "grey.50" }}>
                    <TableCell sx={{ py: 0.5 }}>Type</TableCell>
                    <TableCell sx={{ py: 0.5 }}>Name & ID</TableCell>
                    <TableCell sx={{ py: 0.5 }} align="center">
                      Lv
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {skills.length > 0 ? (
                    skills.map((skill: any, skillIndex: number) => {
                      let skillName = "Loading...";
                      let skillDetailsMap: Record<number, any> = {};
                      if (skill.skill_master_type === 1) skillDetailsMap = activeSkillDetails;
                      else if (skill.skill_master_type === 2) skillDetailsMap = passiveSkillDetails;
                      else if (skill.skill_master_type === 3) skillDetailsMap = specialSkillDetails;
                      skillName = skillDetailsMap[skill.skill_id]?.name || "Unknown Name";
                      return (
                        <TableRow key={skillIndex} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell sx={{ py: 0.5, width: "20%" }}>
                            <Chip label={getSkillTypeLabel(skill.skill_master_type)} size="small" color={getSkillTypeColor(skill.skill_master_type)} variant="outlined" />
                          </TableCell>
                          <TableCell sx={{ py: 0.5, fontSize: "0.8rem" }}>
                            {skillName}{" "}
                            <Typography variant="caption" color="text.secondary">
                              (ID: {skill.skill_id})
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ py: 0.5, width: "10%" }} align="center">
                            {skill.SkillLevel}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center" sx={{ py: 1 }}>
                        No skills found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
        {/* Equipment Accordion */}
        <Accordion
          sx={{ "&:before": { display: "none" }, border: 1, borderColor: "divider", margin: "0 !important" }}
          elevation={0}
          expanded={isEquipmentExpanded}
          onChange={handleEquipmentAccordionChange}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-equipment-content-${characterId}-${slotNumber}`}
            id={`panel-equipment-header-${characterId}-${slotNumber}`}
            sx={{ bgcolor: "action.hover", minHeight: "40px", ".MuiAccordionSummary-content": { marginY: "8px" } }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "medium" }}>
              Equipment ({equipmentCards.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 1.5, pt: 1, display: "flex", flexDirection: "column", gap: 1 }}>
            {equipmentCards.length > 0 ? (
              equipmentCards.map((equipment: any, equipmentIndex: number) => {
                const equipCard = equipment.CalculatedEquipmentCard;
                const equipmentId = equipCard?.EquipmentCardId;
                const equipmentInfo = equipmentId ? equipmentDetails[equipmentId] : null;
                const equipSlot = equipment.EquipmentCardSlotNumber;
                if (!equipCard || !equipmentId)
                  return (
                    <Paper key={`equip-missing-${equipmentIndex}`} variant="outlined" sx={{ p: 1, bgcolor: "grey.50" }}>
                      <Typography variant="caption" color="text.secondary">
                        Equipment data missing for slot {equipSlot || "N/A"}
                      </Typography>
                    </Paper>
                  );
                return (
                  <Paper key={equipmentIndex} variant="outlined" sx={{ p: 1.5 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 0.5 }}>
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Slot {equipSlot}: {equipmentInfo?.name || "Unknown Equipment"}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        ID: {equipmentId}
                      </Typography>
                    </Box>
                    <Typography variant="caption" display="block" color="text.secondary" sx={{ mb: 1 }}>
                      {equipmentInfo?.card_name || ""}
                    </Typography>
                    <Grid container spacing={1} sx={{ fontSize: "0.875rem", mb: 1 }}>
                      <Grid item xs={4}>
                        HP: {equipCard.HP}
                      </Grid>
                      <Grid item xs={4}>
                        ATK: {equipCard.ATK}
                      </Grid>
                      <Grid item xs={4}>
                        DEF: {equipCard.DEF}
                      </Grid>
                    </Grid>
                    <Divider sx={{ my: 0.5 }} />
                    <Typography variant="caption" display="block" sx={{ fontWeight: "medium" }}>
                      Passive Skill Levels:
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1.2 }}>
                      Lvl 1: {equipCard.PassiveSkillLevel1 ?? "N/A"} | Lvl 2: {equipCard.PassiveSkillLevel2 ?? "N/A"} | Lvl 3: {equipCard.PassiveSkillLevel3 ?? "N/A"}
                    </Typography>
                  </Paper>
                );
              })
            ) : (
              <Typography variant="body2" align="center" color="text.secondary" sx={{ py: 1 }}>
                No equipment assigned.
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

// --- Main Component ---
export const UserPartyDetail: React.FC = () => {
  // Component States
  const [userId, setUserId] = useState("");
  const [partyList, setPartyList] = useState<any[]>([]);
  const [characterDetails, setCharacterDetails] = useState<Record<number, any>>({});
  const [equipmentDetails, setEquipmentDetails] = useState<Record<number, any>>({});
  const [activeSkillDetails, setActiveSkillDetails] = useState<Record<number, any>>({});
  const [passiveSkillDetails, setPassiveSkillDetails] = useState<Record<number, any>>({});
  const [specialSkillDetails, setSpecialSkillDetails] = useState<Record<number, any>>({});
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [expandedPartyAccordions, setExpandedPartyAccordions] = useState<Record<string | number, boolean>>({});
  const [showScrollTopButton, setShowScrollTopButton] = useState(false); // Scroll-to-top state

  const dataProvider = useDataProvider();

  // Data Fetching Mutation
  const { mutate: handleFetchParties, isLoading: isFetching } = useMutation(
    async (userIdToFetch: string) => {
      if (!userIdToFetch) throw new Error("User ID is required.");
      setFetchError(null);
      setPartyList([]);
      const partyResponse = await dataProvider.getList("UserPartyDetail", {
        filter: { userId: userIdToFetch },
        pagination: { page: 1, perPage: 100 },
        sort: { field: "Party.PartyNumber", order: "ASC" },
      });
      if (!partyResponse.data || partyResponse.data.length === 0) throw new Error(`No party data found for User ID: ${userIdToFetch}.`);
      const parties = partyResponse.data;
      const characterCardIds = new Set<number>();
      const equipmentCardIds = new Set<number>();
      const activeSkillIds = new Set<number>();
      const passiveSkillIds = new Set<number>();
      const specialSkillIds = new Set<number>();
      parties.forEach((party) => {
        party.Party?.PartyMembers?.forEach((member: any) => {
          const charCardId = member.CalculatedPartyCharacterCard?.CalculatedCharacterCard?.CharacterCardId;
          if (charCardId != null) characterCardIds.add(charCardId);
          member.CalculatedPartyEquipmentCards?.forEach((equipment: any) => {
            const equipCardId = equipment.CalculatedEquipmentCard?.EquipmentCardId;
            if (equipCardId != null) equipmentCardIds.add(equipCardId);
          });
          member.CalculatedPartyCharacterCard?.CalculatedCharacterCard?.CharacterCardSkills?.forEach((skill: any) => {
            if (skill.skill_id != null) {
              if (skill.skill_master_type === 1) activeSkillIds.add(skill.skill_id);
              if (skill.skill_master_type === 2) passiveSkillIds.add(skill.skill_id);
              if (skill.skill_master_type === 3) specialSkillIds.add(skill.skill_id);
            }
          });
        });
      });
      const fetchList = async (resource: string, ids: Set<number>) => {
        if (ids.size === 0) return { data: [] };
        const perPage = Math.max(1, Math.min(ids.size, 1000));
        try {
          return await dataProvider.getList(resource, { filter: { ids: Array.from(ids) }, pagination: { page: 1, perPage: perPage }, sort: { field: "id", order: "ASC" } });
        } catch (error) {
          console.error(`Error fetching ${resource} for ${ids.size} IDs:`, error);
          return { data: [] };
        }
      };
      const [charRes, equipRes, activeRes, passiveRes, specialRes] = await Promise.all([
        fetchList("CharacterCard", characterCardIds),
        fetchList("EquipmentCard", equipmentCardIds),
        fetchList("ActiveSkill", activeSkillIds),
        fetchList("PassiveSkill", passiveSkillIds),
        fetchList("SpecialSkill", specialSkillIds),
      ]);
      const createMap = (response: { data: any[] }) =>
        response.data.reduce((acc: any, item: any) => {
          if (item?.id != null) acc[item.id] = item;
          return acc;
        }, {});
      setCharacterDetails(() => createMap(charRes));
      setEquipmentDetails(() => createMap(equipRes));
      setActiveSkillDetails(() => createMap(activeRes));
      setPassiveSkillDetails(() => createMap(passiveRes));
      setSpecialSkillDetails(() => createMap(specialRes));
      setPartyList(parties);
      return parties;
    },
    {
      onSuccess: (data) => {
        console.log(`Successfully fetched ${data?.length || 0} parties.`);
      },
      onError: (error: any) => {
        console.error("Failed to fetch parties:", error);
        const errorMessage = error instanceof Error ? error.message : "An unknown error occurred.";
        setFetchError(errorMessage);
        setPartyList([]);
      },
    }
  );

  // Scroll Event Listener Effect for Scroll-to-Top Button
  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScrollTopButton && window.scrollY > 300) {
        setShowScrollTopButton(true);
      } else if (showScrollTopButton && window.scrollY <= 300) {
        setShowScrollTopButton(false);
      }
    };
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [showScrollTopButton]);

  // Handlers
  const handleSubmit = useCallback(() => {
    if (userId.trim()) {
      setExpandedPartyAccordions({});
      handleFetchParties(userId.trim());
    } else {
      setFetchError("Please enter a User ID.");
    }
  }, [userId, handleFetchParties]);
  const handleUserIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(event.target.value);
    if (fetchError) setFetchError(null);
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") handleSubmit();
  };
  const handleTogglePartyAccordions = useCallback((partyId: string | number) => {
    setExpandedPartyAccordions((prev) => ({ ...prev, [partyId]: !prev[partyId] }));
  }, []);
  const scrollToParty = (partyId: string | number) => {
    const element = document.getElementById(String(partyId));
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      element.style.transition = "outline 0.1s ease-in-out";
      element.style.outline = "2px solid #1976d2";
      element.style.outlineOffset = "2px";
      setTimeout(() => {
        if (element) element.style.outline = "none";
      }, 1200);
    } else {
      console.warn(`Element with ID ${partyId} not found for scrolling.`);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // --- Render JSX ---
  return (
    <>
      <Title title="編成詳細" />
      <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
        {/* Form Input Section */}
        <Paper sx={{ p: 2, mb: 3, display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center", gap: 2 }} elevation={1}>
          <TextField
            label="User ID"
            variant="outlined"
            value={userId}
            onChange={handleUserIdChange}
            onKeyPress={handleKeyPress}
            disabled={isFetching}
            size="small"
            sx={{ width: "100%", maxWidth: { sm: 400 } }}
            error={!!fetchError && !userId.trim()}
            helperText={fetchError && !userId.trim() ? fetchError : null}
          />
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isFetching || !userId.trim()}
            startIcon={isFetching ? <CircularProgress size={20} color="inherit" /> : null}
            sx={{ width: { xs: "100%", sm: "auto" }, minWidth: 150 }}
          >
            {" "}
            {isFetching ? "Loading..." : "Fetch Parties"}{" "}
          </Button>
        </Paper>

        {/* General Fetch Error Display */}
        {fetchError && userId.trim() && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {" "}
            {fetchError}{" "}
          </Alert>
        )}

        {/* Loading Indicator */}
        {isFetching && (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 4, flexDirection: "column", gap: 1 }}>
            <CircularProgress />{" "}
            <Typography sx={{ mt: 1 }} color="text.secondary">
              Fetching party details...
            </Typography>
          </Box>
        )}

        {/* Party Jump Links Section */}
        {!isFetching && partyList.length > 0 && (
          <Paper sx={{ p: 1.5, mb: 3 }} elevation={1}>
            <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: "medium" }}>
              {" "}
              Jump to Party:{" "}
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {partyList.map((partyData) => {
                const partyId = partyData.Party?.UserParty?.UserPartyId || `party-${partyData.Party?.PartyNumber}`;
                const partyName = partyData.Party.UserParty?.PartyName;
                const partyLabel = `Party ${partyData.Party.PartyNumber}${partyName ? `: ${partyName.substring(0, 15)}${partyName.length > 15 ? "..." : ""}` : ""}`;
                return (
                  <Chip
                    key={`jump-${partyId}`}
                    label={partyLabel}
                    onClick={() => scrollToParty(partyId)}
                    clickable
                    size="small"
                    variant="outlined"
                    color="primary"
                    sx={{ cursor: "pointer" }}
                  />
                );
              })}
            </Box>
          </Paper>
        )}

        {/* Party List Display */}
        {!isFetching && partyList.length > 0 && (
          <Grid container spacing={3}>
            {partyList.map((partyData) => {
              const partyId = partyData.Party?.UserParty?.UserPartyId || `party-${partyData.Party?.PartyNumber}`;
              const areAccordionsExpanded = expandedPartyAccordions[partyId] || false;
              const hasMembers = partyData.Party?.PartyMembers?.length > 0;
              return (
                <Grid item xs={12} key={partyId} id={String(partyId)}>
                  {" "}
                  {/* Add id for scrolling target */}
                  <Card variant="outlined" elevation={1}>
                    <CardHeader
                      title={`Party ${partyData.Party.PartyNumber}: ${partyData.Party.UserParty?.PartyName || "Unnamed Party"}`}
                      sx={{ bgcolor: "primary.light", color: "primary.contrastText" }}
                      titleTypographyProps={{ variant: "h6", fontWeight: "bold" }}
                    />
                    <CardContent sx={{ pt: 1.5 }}>
                      {/* Party Overview */}
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: "medium" }}>
                          Party Overview
                        </Typography>
                        <Paper variant="outlined" sx={{ p: 1.5 }}>
                          <Grid container spacing={1}>
                            <Grid item xs={6} sm={3}>
                              <Typography variant="body2">
                                <strong>Power:</strong> {partyData.Party.TotalPower}
                              </Typography>
                            </Grid>{" "}
                            <Grid item xs={6} sm={3}>
                              <Typography variant="body2">
                                <strong>HP:</strong> {partyData.Party.TotalHP}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Typography variant="body2">
                                <strong>ATK:</strong> {partyData.Party.TotalATK}
                              </Typography>
                            </Grid>{" "}
                            <Grid item xs={6} sm={3}>
                              <Typography variant="body2">
                                <strong>DEF:</strong> {partyData.Party.TotalDEF}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Box>
                      {/* Divider and Expand/Collapse Button */}
                      {hasMembers && (
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                          <Divider sx={{ flexGrow: 1, mr: 1 }}>
                            <Chip label="Party Members" size="small" />
                          </Divider>
                          <Tooltip title={areAccordionsExpanded ? "Collapse All Member Details" : "Expand All Member Details"}>
                            <IconButton size="small" onClick={() => handleTogglePartyAccordions(partyId)} color="primary" disabled={!hasMembers}>
                              {" "}
                              {areAccordionsExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}{" "}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                      {/* Party Members Grid */}
                      <Grid container spacing={2} alignItems="stretch">
                        {hasMembers ? (
                          partyData.Party.PartyMembers.map((member: any, memberIndex: number) => {
                            const memberKey =
                              member.CalculatedPartyCharacterCard?.CharacterCardSlotNumber != null
                                ? `member-${partyId}-${member.CalculatedPartyCharacterCard.CharacterCardSlotNumber}`
                                : `member-${partyId}-${memberIndex}`;
                            return (
                              <Grid item xs={12} md={6} lg={4} key={memberKey}>
                                {" "}
                                <MemberCard
                                  member={member}
                                  characterDetails={characterDetails}
                                  equipmentDetails={equipmentDetails}
                                  activeSkillDetails={activeSkillDetails}
                                  passiveSkillDetails={passiveSkillDetails}
                                  specialSkillDetails={specialSkillDetails}
                                  areAccordionsExpanded={areAccordionsExpanded}
                                />{" "}
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid item xs={12}>
                            {" "}
                            <Typography align="center" color="text.secondary" sx={{ py: 2 }}>
                              This party has no members.
                            </Typography>{" "}
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}

        {/* No Results Message */}
        {!isFetching && !fetchError && partyList.length === 0 && userId.trim() && (
          <Alert severity="info" sx={{ mt: 2 }}>
            {" "}
            No parties found for the specified User ID. Check the ID and try again.{" "}
          </Alert>
        )}

        {/* Scroll to Top Button */}
        <Zoom in={showScrollTopButton}>
          <Box onClick={scrollToTop} role="presentation" sx={{ position: "fixed", bottom: 16, right: 16, zIndex: 1050 }}>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </Box>
        </Zoom>
      </Box>
    </>
  );
};

// Optional: Export the component
// export default UserPartyDetail;
