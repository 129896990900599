import React, { useState } from "react";
import { Datagrid, TextField, TextInput, FunctionField, ListProps, useDataProvider, useNotify } from "react-admin";
import { CommonList, DateTimeWithSecInput, YMDHMSDateField } from "../Common";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { useMutation } from "react-query";
import VisibilityIcon from "@mui/icons-material/Visibility";

const logAdminAccessListFilters = [
  <DateTimeWithSecInput key="from" label="日時From" source="CreatedAt_from" />,
  <DateTimeWithSecInput key="to" label="日時To" source="CreatedAt_to" />,
  <TextInput key="mailAddressLike" source="MailAddress_like" label="メールアドレス Like" />,
  <TextInput key="requestPathLike" source="RequestPath_like" label="パス Like" />,
  <TextInput key="method" source="Method" label="リクエストメソッド" />,
  <TextInput key="requestBodyLike" source="RequestBody_like" label="リクエストボディ Like" />,
];

export const LogAdminAccessList = (props: ListProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<string | null>(null);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { mutate: handleDetailClick, isLoading: isDetailLoading } = useMutation(
    async (id: string) => {
      const { data } = await dataProvider.getOne("LogAdminAccess", { id });
      return data;
    },
    {
      onSuccess: (data) => {
        setDialogContent(JSON.stringify(data, null, 2));
        setDialogOpen(true);
      },
      onError: (error: any) => {
        notify(error.message, { type: "warning" });
      },
    }
  );

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogContent(null);
  };

  const renderTableContent = (data: any) => {
    if (!data || typeof data !== "object" || !data.RequestBody) {
      return <div>データがありません。</div>;
    }

    return (
      <pre
        style={{
          backgroundColor: "#f6f8fa",
          padding: "16px",
          borderRadius: "8px",
          fontSize: "14px",
          lineHeight: "1.5",
          overflowX: "auto",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        {JSON.stringify(JSON.parse(data.RequestBody), null, 2)}
      </pre>
    );
  };

  return (
    <>
      <CommonList {...props} filters={logAdminAccessListFilters} sort={{ field: "CreatedAt", order: "DESC" }}>
        <Datagrid bulkActionButtons={false}>
          <YMDHMSDateField source="CreatedAt" label="日時" />
          <TextField source="MailAddress" label="メールアドレス" />
          <TextField source="RequestPath" label="パス" />
          <TextField source="Method" label="リクエストメソッド" />
          <FunctionField
            label="リクエストボディ"
            render={(record: any) =>
              record.HasDetail ? (
                <Button variant="text" color="primary" size="small" startIcon={<VisibilityIcon />} onClick={() => handleDetailClick(record.id)} disabled={isDetailLoading}>
                  表示
                </Button>
              ) : null
            }
          />
        </Datagrid>
      </CommonList>
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>リクエストボディ</DialogTitle>
        <DialogContent>{renderTableContent(dialogContent && JSON.parse(dialogContent))}</DialogContent>
      </Dialog>
    </>
  );
};
