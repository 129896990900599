import { announcementTag } from ".";

export class AnnouncementBuilder {
  constructor() {}

  public static build(title: string, body: string, tag: string, date: Date) {
    const childWindow = (document.getElementById("preview-html") as HTMLIFrameElement).contentWindow;
    if (!childWindow) return;

    const child = childWindow.document;

    let headerElement = child.getElementById("header");
    const titleElement = child.getElementById("title");
    const bodyElement = child.getElementById("body");

    if (!headerElement || !titleElement || !bodyElement) {
      return;
    }

    const tagChild = this.buildTagElement(tag);
    const dateChild = this.buildDateElement(date);
    headerElement = this.removeAllChild(headerElement);
    headerElement.appendChild(tagChild);
    headerElement.appendChild(dateChild);

    const titleChild = this.buildTitleElement(title);
    titleElement.replaceWith(titleChild);

    const bodyChild = this.buildBodyElement(body);
    bodyElement.replaceWith(bodyChild);

    return;
  }

  public static buildTagElement(tag: string): HTMLParagraphElement {
    const element = document.createElement("p");
    const className = (Object.keys(announcementTag) as Array<keyof typeof announcementTag>).find((key) => announcementTag[key] === tag) ?? announcementTag.info;
    element.className = `tag tag_${className}`;
    element.textContent = tag;

    return element;
  }

  public static buildDateElement(date: Date): HTMLParagraphElement {
    const element = document.createElement("p");
    element.className = "date";
    element.textContent = date.toLocaleString();

    return element;
  }

  public static buildTitleElement(title: string): HTMLParagraphElement {
    const parent = document.createElement("div");
    parent.id = "title";
    parent.className = "block_title";

    const element = document.createElement("p");
    element.textContent = title;

    parent.appendChild(element);

    return parent;
  }

  public static buildBodyElement(body: string): HTMLParagraphElement {
    const element = document.createElement("div");
    element.id = "body";
    element.className = "text";
    element.innerHTML = body;

    return element;
  }

  public static removeAllChild(element: HTMLElement): HTMLElement {
    element.innerHTML = "";
    return element;
  }
}
